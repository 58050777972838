import React, { createContext, PropsWithChildren, useEffect, useState } from "react";

export type THEME = 'light' | 'dark';
export const THEMES = ['light', 'dark'];
const THEME_DEFAULT: THEME = 'light';
const THEME_LOCALSTORAGE_KEY = 'theme';

interface ThemeContextInterface {
  theme: THEME;
  setTheme(theme: THEME): void;
}

export const ThemeContext = createContext({
  theme: 'light',
  setTheme: (theme: THEME) => {},
} as ThemeContextInterface);

const ThemeProvider: React.FC<PropsWithChildren> = ({children}) => {
  const getTheme = (): THEME => {
    const t = localStorage.getItem(THEME_LOCALSTORAGE_KEY);
    if (!t || THEMES.indexOf(t) === -1) {
      // Dark theme at night :-)
      const hour = new Date().getHours();
      if (hour > 22 || hour < 7) {
        return 'dark';
      }
      return THEME_DEFAULT;
    }
    return t as THEME;
  }

  const [theme, setThemeState] = useState<THEME>(getTheme());

  const setTheme = (theme: THEME) => {
    setThemeState(theme);
    localStorage.setItem(THEME_LOCALSTORAGE_KEY, theme)
  }

  useEffect(() => {
    // Remove invalid themes from local storage
    const t = localStorage.getItem(THEME_LOCALSTORAGE_KEY);
    if (t && THEMES.indexOf(t) === -1) {
      localStorage.removeItem(THEME_LOCALSTORAGE_KEY);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{
      theme,
      setTheme,
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider;
