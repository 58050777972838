import React, { useEffect, useState } from "react";
import "./ImageViewer.scss";
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@primer/octicons-react";

import images from "../../images";

const imagesCount = images.length;

export function ImageViewer() {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  const changeImage = (direction: -1 | 1) => {
    const i = ((currentImage + direction) + imagesCount) % imagesCount
    setCurrentImage(i);
  }

  const getImageURL = (filename: string) => `/static/img/${filename}`

  const getPrevImg = () => {
    const i = ((currentImage - 1) + imagesCount) % imagesCount
    return images[i]
  }

  const getNextImg = () => {
    const i = ((currentImage + 1) + imagesCount) % imagesCount
    return images[i]
  }

  const prev = () => changeImage(-1);
  const next = () => changeImage(1);

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX = e.changedTouches[0].screenX;
  }

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndX = e.changedTouches[0].screenX;

    // Minimum swipe distance
    const distance = Math.abs(touchStartX - touchEndX)
    if (distance < 30) return;

    if (touchStartX > touchEndX) {  // left swipe
      next();
    } else {
      prev();
    }
  }

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey) return;

      if (e.key === "ArrowLeft" || e.key.toLowerCase() === "h") prev();
      else if (e.key === "ArrowRight" || e.key.toLowerCase() === "l") next();
      else if (e.key.toLowerCase() === "f") setFullscreen(!isFullscreen);
      else if (e.key === "Escape") setFullscreen(false);
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Run on unmount
      document.removeEventListener("keydown", handleKeyPress);
    }
  });

  return (
    <div>
      <div className={`ImageViewer${isFullscreen ? ' ImageViewer--Fullscreen' : ''}`}
           onTouchStart={handleTouchStart}
           onTouchEnd={handleTouchEnd}>
        {isFullscreen ? (
          <button className={"ImageViewer__Button ImageViewer__CloseFullscreen"}
                  onClick={() => setFullscreen(false)}>
            <XIcon/>
          </button>
        ) : (<></>)}
        <button className={"ImageViewer__Button"} onClick={prev}>
          <ChevronLeftIcon/>
        </button>
        <div className={"ImageViewer__Main"}>
          <div className={"ImageViewer__ImageContainer"}>
            <img src={getImageURL(getPrevImg().filename)} style={{display: "none"}}/>

            <img src={getImageURL(images[currentImage].filename)}
                 alt={images[currentImage].title}
                 onClick={() => setFullscreen(!isFullscreen)}/>

            <img src={getImageURL(getNextImg().filename)} style={{display: "none"}}/>
          </div>
          <div className={"ImageViewer__Title"}>{images[currentImage].title}</div>
        </div>
        <button className={"ImageViewer__Button"} onClick={next}>
          <ChevronRightIcon/>
        </button>
      </div>
    </div>
  )
}
