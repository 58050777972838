export const TITLE = "Robin Espinosa";

export const NAVIGATION_LINKS = [
  {path: "/about", label: "about"},
  // {path: "/gallery", label: "gallery"},
];

export const EXTERNAL_LINKS = [
  {url: "https://instagram.com/robinespi", label: "insta"},
  {url: "https://vsco.co/robinespi", label: "vsco"},
  {url: "https://github.com/robines", label: "github"},
  {url: "https://linkedin.com/in/robinespi", label: "linkedin"},
];

export const SKILLS = [
  {
    label: "Languages",
    items: ["Go", "PHP", "Python", "SQL", "C++", "C", "JavaScript", "TypeScript", "Java", "Bash"]
  },
  {
    label: "Sysadmin",
    items: ["Docker", "nginx", "GitLab", "Gitea", "Drone", "Nagios", "Proxmox", "HAProxy", "BIND (DNS)"]
  },
  {
    label: "Misc.",
    items: ["Vue.js", "React", "SASS/SCSS", "Twig", "Slim"]
  },
  {
    label: "Tools",
    items: ["Make", "Vagrant", "ffmpeg", "Webpack"]
  },
  {
    label: "Distros",
    items: ["Fedora", "Debian", "Gentoo", "Arch Linux"]
  }
];

export const PHOTOGRAPHY = [
  {
    label: "Cameras",
    items: ["Fujifilm X-T4", "Canon AE-1 Program", "Olympus µ[mju:]-1"]
  },
  {
    label: "Color Film",
    items: ["Portra 400", "Fuji X-Tra 400"]
  },
  {
    label: "BW Film",
    items: ["HP5 Plus 400", "Tri-X 400"]
  }
]
