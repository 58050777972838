import { Outlet } from "react-router-dom";
import "./Root.scss";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { ThemeSwitchButton } from "../../components";

export function Root() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`App theme-${theme}`}>
      <ThemeSwitchButton/>
      <Outlet/>
    </div>
  )
}
