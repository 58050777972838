import React, { PropsWithChildren } from "react";
import "./NarrowContainer.scss";

export const NarrowContainer: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <div className="narrow-container">
      {children}
    </div>
  )
}
