import "./ItemList.scss";

interface ItemListCategory {
  label: string;
  items: string[];
}

interface ItemListProps {
  items: ItemListCategory[];
}

export function ItemList(props: ItemListProps) {
  return (
    <div className="ItemList">
      {props.items.map((section, i) => (
        <section key={i}>
          <div className="ItemList__Label">{section.label}</div>
          <div>{section.items.join(", ")}</div>
        </section>
      ))}
    </div>
  )
}
