import { ImageViewer } from "../../components";
import React from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
import { EXTERNAL_LINKS, NAVIGATION_LINKS, TITLE } from "../../config";

export function Home() {
  return (
    <div className="Home">
      <ImageViewer/>
      <header className={"Home__Header"}>
        <h1 className={"Home__Title"}>{TITLE}</h1>
        <nav className={"Home__Navigation"}>
          <ul>
            {EXTERNAL_LINKS.map((l) => (
              <li key={l.label}>
                <a href={l.url}>{l.label}</a>
              </li>
            ))}
          </ul>
          <ul>
            {NAVIGATION_LINKS.map((l) => (
              <li key={l.label}>
                <Link to={l.path}>{l.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </div>
  );
}
