import React from "react";
import { TITLE } from "../../config";
import "./Header.scss";
import { Link } from "react-router-dom";

interface HeaderProps {
  title: string;
}

export function Header(props: HeaderProps) {
  return (
    <div className="Header">
      <div className="Header__Title"><Link to="/">{TITLE}</Link></div>
      <div className="Header__Page">{props.title}</div>
    </div>
  )
}
